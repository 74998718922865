#loginPage .topButtonContainer {
  display: none;
}

#loginPage .titleTitle, #settingsPage .titleTitle {
  overflow: visible;
  padding-left: 0;
}

.changeBox .changeSubmit {
  width: 253px;
  height: 48px;
  margin: 32px 60px 0 0;
  font-size: 16px;
  background-color: #454545;
  /* layer fill content */
  border: 1px solid #000;
  /* stroke */
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* prevents bg color from leaking outside the border */;
}

.changeBox .changeBoxTitle {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  text-shadow: 0 1px #fff;
}

.loginButton {
  float: left;
  width: 126px;
  height: 29px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #888;
  padding-top: 7px;
  text-shadow: 0 -1px 0 #000;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.loginButton.selected {
  cursor: default;
}

#newUser {
  border-right: 1px solid black;
}

.changeBox {
  margin: 24px 0 40px 0;
  padding: 40px;
  overflow: auto;
}

#loginExisting, #loginNew, #useKey, #forgot, #recover, #deleteUser, .changeBox {
  background-color: #f2f2f2;
  border: 1px solid #b2b2b2;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  clear: both;
}

#loginExisting {
  height: 124px;
}

#recover {
  padding-bottom: 32px;
}

#loginNew, #deleteUser {
  padding: 20px 0 50px 0;
}

#useKey {
  height: 124px;
}

#forgot {
  height: 124px;
}

#usernameArea, #passwordArea, #newPasswordArea, #newPasswordVerifyArea, #newUsernameArea, #sendCreationEmailArea, #accessKeyArea, #firstNameArea, #lastNameArea, #personTitleArea, #spamArea, #eAlertsArea, #termsArea, #requiredArea, .changeArea {
  position: relative;
  margin: 24px 60px 0 60px;
  min-height: 30px;
  font-size: 16px;
  color: #656565;
}

#loginExisting #usernameArea, #loginExisting #passwordArea {
  width: 215px;
  margin-left: 60px;
  float: left;
}

#requiredArea {
  float: left;
  top: 9px;
  margin-left: 20px;
}

#loginExisting label, #loginNew label, #useKey label, #forgot label, #recover label, .changeBox label {
  display: block;
  margin-bottom: 6px;
}

#loginExisting input:not([type=submit]), #loginNew input, #useKey input, #forgot input, #recover input, #deleteUser input:not([type=submit]), .changeBox input:not([type=submit]), .changeBox select {
  display: block;
  height: 28px;
  line-height: 28px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 1, 1, .2);
  padding-left: 10px;
  /* stroke */
  background-color: #e5e5e5;
  /* color overlay */
  -moz-box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
  /* inner shadow */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
  /* inner shadow */
  box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
  /* inner shadow */;
}

#eAlertsArea label, #spamArea label, #termsArea label, .changeBox .changeArea.checkboxArea label {
  float: left;
  position: relative;
  top: 4px;
}

#eAlertsArea #eAlerts, #spamArea #spam, #termsArea #terms, #sendCreationEmailArea #sendCreationEmail, .changeBox input[type='checkbox'] {
  width: 30px;
  height: 30px;
  padding-left: 0;
  border: none;
  float: right;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.requiredBlip, .errorBlip {
  position: absolute;
  left: -20px;
  top: 32px;
  width: 10px;
  height: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

#deleteUser .errorBlip, #deleteUser .requiredBlip {
  top: 26px;
}

#termsArea .errorBlip, #termsArea .requiredBlip {
  top: 10px;
}

#requiredArea .requiredBlip {
  top: 5px;
}

.errorBlip {
  background-color: #d92323;
}

.requiredBlip {
  background-color: #49A942;
}
.teal .requiredBlip {
  background-color: #27676D;
}

.errorField {
  color: #d82323;
  font-size: 14px;
  clear: both;
}

#loginForgotPassword {
  color: #666;
  font-size: 14px;
  text-decoration: none;
}

#loginForgotPassword:hover {
  color: #333;
}

#passwordArea .errorField {
  width: 230px;
}

#loginPage #topLoginButton {
  display: none;
}

#loginButton, #newButton, #useKeySubmit, #forgotButton, .genericLoginButton, #deleteButton, .changeSubmit {
  float: right;
  margin-top: 20px;
  padding: 14px 26px;
  color: #ccc;
  font-size: 14px;
  text-shadow: 0 -1px 0 rgba(0, 1, 1, 0.5);
  border: 1px solid #212122;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #454545;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  text-decoration: none;
}

#loginButton:hover, #newButton:hover, #useKeySubmit:hover, #forgotButton:hover, .genericLoginButton:hover, .changeSubmit:hover {
  color: #fff;
}

.loginInfo {
  margin-top: 40px;
  margin-bottom: 40px;
}

.requestBlock {
  clear: both;
  float: left;
  width: 700px;
}

#loginButton {
  margin-top: 40px;
  margin-right: 32px;
  font-size: 16px;
}

.genericLoginButton {
  font-size: 16px;
  padding: 12px 14px;
  margin: -14px 0 0 0;
  float: none;
}

.genericLoginButtonBlock {
  float: right;
  text-align: right;
  min-width: 200px;
}

.requestBlock .title {
  margin-top: 0;
  margin-bottom: 8px;
}

.requestCopy {
  font-size: 16px;
}

.requestCopy a {
  font-weight: 600;
  color: #333;
  text-decoration: none;
}

.requestCopy a:hover {
  color: #000;
  text-decoration: underline;
}

#errorMessageArea {
  position: relative;
  font-weight: 600;
  color: #d92323;
  margin-bottom: 16px;
  margin-left: 20px;
}

#errorMessageArea .errorBlip {
  left: -16px;
  top: 4px;
}